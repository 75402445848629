<template>
  <div>
    <PageTitle v-bind:title="'Base Etym Vars'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button
            v-if="config.editable"
            variant="primary"
            size="sm"
            :disabled="!$store.getters.checkRole('legam.scripta.baseetymvar.add')"
            class="float-right mb-4"
            v-b-modal.modal-new-baseetymvar
          >
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new base etym var
          </b-button>
          <b-modal
            v-if="config.editable"
            id="modal-new-baseetymvar"
            title="New base etym var"
            ok-title="Save"
            @ok="saveBaseetymvar"
            size="lg"
          >
            <BaseEtymVarNewEdit />
          </b-modal>
          <LegamDataTables v-bind="$data" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
* >>> .tdAction {
  width: 150px;
}
</style>

<script>
// import { mapState } from 'vuex'
import router from "@/router";
import PageTitle from "@/components/PageTitle.vue";
import LegamDataTables from "@/components/LegamDataTables.vue";
import tdColAction from "@/components/tdColAction";
import BaseEtymVarNewEdit from "@/components/Scripta/BaseEtymVarNewEdit";
import HelpStore from "@/store/helpstore.js";
import config from '@/config.js'

export default {
  name: "BaseEtymVars",
  data() {
    return {
      columns: [
        {
          title: "Form",
          field: "form",
          sortable: true,
          searchable: true,
        },
        {
          title: "Comment",
          field: "comment",
          sortable: true,
          searchable: true,
        },
        {
          title: "Heads",
          field: "heads",
          tdComp: tdColAction,
          sortable: true,
          searchable: true,
          transformData: function (row) {
            return row.heads.map(head => {
              return {
                text: head.form,
                icon: null,
                id: head.id,
                to: {
                  name: "HeadDetails",
                  params: {
                    id: head.id,
                  },
                },
              }
            })
          }
        },
        {
          title: "Action",
          field: "action",
          tdComp: tdColAction,
          thClass: "tdAction",
          transformData: function (row) {
            return [
              {
                text: null,
                icon: "list-ul",
                id: row.id,
                to: {
                  name: "BaseEtymVarDetails",
                  params: {
                    id: row.id,
                  },
                },
              },
            ];
          },
        },
      ],
      url: "/baseetymvars/datatables",
      searchableByColumn: true,
      config: config
    };
  },
  components: {
    PageTitle,
    LegamDataTables,
    BaseEtymVarNewEdit,
  },
  methods: {
    saveBaseetymvar() {
      this.$store.dispatch("createBaseetymvar", HelpStore.item).then((response) => {
        router.push(`/baseetymvar/${response.data.id}`);
      });
    },
  },
};
</script>
